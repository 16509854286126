import { FailedResponse, SuccessResponse } from '../common';
import { RestoreRequestForm } from '@/types/restore';

export interface ITenantMRepository {
    /**
     * テナント情報の登録
     *
     * @param companyId
     * @param tenantName
     * @param tenantIdentifier
     * @param statsDomainAddressId
     */
    postTenantCreation(
        companyId: number,
        tenantName: string,
        tenantIdentifier: string,
        statsDomainAddressId: number,
    ): Promise<TenantCreationResponse>;

    /**
     * 削除画面用: テナントとシステム情報を取得
     */
    getTenantIndexWithSystem(companyId: number): Promise<TenantFetchResponse>;

    /**
     * テナント削除
     */
    deleteTenant(tenantIds: number[]): Promise<TenantDeleteResponse>;

    /**
     * テナント詳細の取得
     *
     * @param tenantId
     */
    getTenantDetail(tenantId: number): Promise<TenantDetailFetchResponse>;

    /**
     * 簡易ログイン用テナント情報取得
     *
     * @param companyId
     */
    getTenantIndexForSimpleLogin(companyId: number): Promise<TenantFetchForSimpleLoginResponse>;

    /**
     * @param RestoreRequestForm<TenantRestoreResponse>
     *
     * @return Promise<TenantRestoreResponse>
     */
    restoreTenants(params: RestoreRequestForm): Promise<TenantRestoreResponse>;
}

export type TenantCreationResponse = TenantCreationSuccessResponse | FailedResponse;

export type TenantCreationSuccessResponse = {
    status: boolean;
    data: {
        data: {
            id: number;
        };
    };
};

export type TenantFetchResponse = TenantSuccessResponse<TenantSystemInfo[]> | FailedResponse;
export type TenantFetchForSimpleLoginResponse = TenantSuccessResponse<TenantSystemWithApiKeyInfo[]> | FailedResponse;
export type TenantDeleteResponse = SuccessResponse | FailedResponse;
export type TenantRestoreResponse = SuccessResponse | FailedResponse;
export type TenantDetailFetchResponse = TenantDetailSuccessResponse | FailedResponse;

export type TenantSuccessResponse<T> = {
    status: boolean;
    data: {
        data: T;
    };
};

export type TenantSystemWithApiKeyInfo = {
    apiKeys: ApiKey[];
    tenantIdentifier: string;
} & TenantSystemInfo;

export type TenantSystemInfo = {
    id: number;
    name: string;
    deletable?: boolean;
    systems: SystemCollaborationInfo[];
};

export type SystemCollaborationInfo = {
    id: number;
    name: string;
    userIdentifier: string;
};

export type TenantDetailSuccessResponse = {
    status: boolean;
    data: {
        data: TenantDetailInfo;
    };
};

export type TenantDetailInfo = {
    id: number;
    name: string;
    tenantIdentifier: string;
    apiKeys: ApiKey[];
    reflectStatus: string;
    companyName: string;
    statsServer: { name: string; domain: string };
    systems: [{ id: number; name: string; userIdentifier: string }];
    schedules: [
        {
            id: number;
            operator: string;
            operationType: string;
            reflectStatus: string;
            reflectedAt: string;
        },
    ];
};

export type ApiKey = {
    key: string;
    roleType: RoleType;
};

export type RoleType = typeof ADMINISTRATOR_ROLE_ID | typeof OPERATOR_ROLE_ID;

export const ADMINISTRATOR_ROLE_ID = 1;
export const OPERATOR_ROLE_ID = 2;
