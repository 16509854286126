import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { FetchCompanyTenantListService } from '@/models/service/Company/FetchCompanyTenantListService';
import { isFailedResponse } from '@/models/interface/common';
import { ErrorStatus } from '@/types/common';
import { CompanyTenantInfo } from '@/models/interface/Management/ICompanyRepository';

const CompanyTenantFetchIndex = container.resolve(FetchCompanyTenantListService);

export type CompanyBasicInfo = Pick<CompanyTenantInfo, 'id' | 'name'>;
export const defaultLabel = '会社選択する';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyMenu = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const masterCompanyList = ref<CompanyBasicInfo[]>([]);
    const companyList = ref<CompanyBasicInfo[]>([]);
    const companyMenuLabel = ref<string>(defaultLabel);
    const selectedCompany = ref<number>(-1);

    provide('error', error);
    provide('masterCompanyList', masterCompanyList);
    provide('companyList', companyList);
    provide('companyMenuLabel', companyMenuLabel);
    provide('selectedCompany', selectedCompany);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyMenu = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const masterCompanyList = inject('masterCompanyList') as Ref<CompanyBasicInfo[]>;
    const companyList = inject('companyList') as Ref<CompanyBasicInfo[]>;
    const companyMenuLabel = inject('companyMenuLabel') as Ref<string>;
    const selectedCompany = inject('selectedCompany') as Ref<number>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initSelectedCompany = (): void => {
        selectedCompany.value = -1;
    };

    const setSelectedCompany = (companyId: number): void => {
        //NOTE: route paramsからのsetter
        selectedCompany.value = companyId;
    };

    const setCompanyList = (data: CompanyTenantInfo[]): void => {
        masterCompanyList.value = data.map((info: CompanyTenantInfo) => ({ id: info.id, name: info.name }));
    };

    const filterCompanyList = (word: string | undefined): void => {
        if (!word) {
            companyList.value = masterCompanyList.value;
            return;
        }
        companyList.value = masterCompanyList.value.filter(
            company => company.name.normalize().indexOf(word.normalize()) !== -1,
        );
    };

    const getCompanyName = (companyId: number): string => {
        const company = masterCompanyList.value.find(company => company.id === companyId);
        return company ? company.name : defaultLabel;
    };

    const initCompanyName = (): void => {
        companyMenuLabel.value = defaultLabel;
    };

    const setSearchCompanyName = (companyId: number): void => {
        const companyName = getCompanyName(companyId);
        setCompanyName(companyName);
    };

    const setCompanyName = (companyName: string): void => {
        if (companyName.length >= 15) {
            companyMenuLabel.value = companyName.slice(0, 12) + '･･･';
            return;
        }

        companyMenuLabel.value = companyName;
    };

    const fetchCompanyList = async (): Promise<void> => {
        //NOTE: undefinedで常に全権取得
        const response = await CompanyTenantFetchIndex.handle(undefined);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            return;
        }

        initStatus();
        setCompanyList(response.data.data);
        filterCompanyList(undefined);
    };

    const isDefaultLabel = (): boolean => companyMenuLabel.value === defaultLabel;

    const isSelectedCompany = (companyId: number): boolean => {
        return selectedCompany.value === companyId;
    };

    const isDefaultSelectCompany = (): boolean => selectedCompany.value === -1;

    return {
        error,
        companyList,
        companyMenuLabel,
        selectedCompany,
        fetchCompanyList,
        filterCompanyList,
        isDefaultLabel,
        isDefaultSelectCompany,
        isSelectedCompany,
        initCompanyName,
        setCompanyName,
        setSearchCompanyName,
        initSelectedCompany,
        setSelectedCompany,
    };
};
