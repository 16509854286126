import { AnalysisPlanInfo } from '@/models/interface/Management/IAnalysisPlanRepository';
import { ICompanyRepository, CompanyTenantFetchResponse } from '@/models/interface/Management/ICompanyRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class FetchCompanyTenantListService {
    constructor(@inject('ICompanyRepository') private readonly repository: ICompanyRepository) {}

    async handle(name?: string, plan?: AnalysisPlanInfo): Promise<CompanyTenantFetchResponse> {
        const planId = plan && plan.id;
        return this.repository.getCompanyIndex(name, planId);
    }
}
