import { render, staticRenderFns } from "./SimpleLogin.vue?vue&type=template&id=2c9fd6b7&scoped=true&"
import script from "./SimpleLogin.vue?vue&type=script&lang=ts&"
export * from "./SimpleLogin.vue?vue&type=script&lang=ts&"
import style0 from "./SimpleLogin.vue?vue&type=style&index=0&id=2c9fd6b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9fd6b7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VContainer,VIcon})
