import { useCircular } from '@/composables/function/Common/useCircular';
import { isFailedResponse } from '@/models/interface/common';
import {
    ADMINISTRATOR_ROLE_ID,
    OPERATOR_ROLE_ID,
    SystemCollaborationInfo,
    TenantSystemWithApiKeyInfo,
} from '@/models/interface/Management/ITenantMRepository';
import { FetchExternalLoginTokenService } from '@/models/service/Auth/FetchExternalLoginTokenService';
import { TenantFetchListForSimpleLoginService } from '@/models/service/Management/TenantFetchListForSimpleLoginService';
import { ErrorStatus } from '@/types/common';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';

const fetchTenantList = container.resolve(TenantFetchListForSimpleLoginService);
const fetchToken = container.resolve(FetchExternalLoginTokenService);
export type CollaboratorInfo = SystemCollaborationInfo;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideSimpleLogin = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const tenantList = ref<TenantSystemWithApiKeyInfo[]>();
    const isTenantSystemFetchProgressing = ref(false);
    const isTokenFetchProgressing = ref(false);

    provide('error', error);
    provide('tenantList', tenantList);
    provide('isTenantSystemFetchProgressing', isTenantSystemFetchProgressing);
    provide('isTokenFetchProgressing', isTokenFetchProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useSimpleLogin = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const tenantList = inject('tenantList') as Ref<TenantSystemWithApiKeyInfo[]>;
    const isTenantSystemFetchProgressing = inject('isTenantSystemFetchProgressing') as Ref<boolean>;
    const isTokenFetchProgressing = inject('isTokenFetchProgressing') as Ref<boolean>;

    const { progressStart, progressEnd } = useCircular();

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const setTenantList = (data: TenantSystemWithApiKeyInfo[]): void => {
        tenantList.value = data;
    };

    const findAdminApiKey = (tenant: TenantSystemWithApiKeyInfo): string | undefined => {
        return tenant.apiKeys.find(apiKey => apiKey.roleType === ADMINISTRATOR_ROLE_ID)?.key;
    };

    const findOperatorApiKey = (tenant: TenantSystemWithApiKeyInfo): string | undefined => {
        return tenant.apiKeys.find(apiKey => apiKey.roleType === OPERATOR_ROLE_ID)?.key;
    };

    const fetchList = async (companyId: number): Promise<void> => {
        progressStart(isTenantSystemFetchProgressing);
        const response = await fetchTenantList.handle(companyId);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            progressEnd(isTenantSystemFetchProgressing);
            return;
        }

        initStatus();
        setTenantList(response.data.data);
        progressEnd(isTenantSystemFetchProgressing);
    };

    const getToken = async (system: SystemCollaborationInfo, apiKey: string): Promise<string | void> => {
        progressStart(isTokenFetchProgressing);
        const response = await fetchToken.handle(system.id, system.userIdentifier, apiKey);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            progressEnd(isTokenFetchProgressing);
            return;
        }

        initStatus();
        progressEnd(isTokenFetchProgressing);
        return response.data.data.token;
    };

    const simpleLogin = async (system: CollaboratorInfo, apiKey: string): Promise<void> => {
        const token = await getToken(system, apiKey);
        window.open(`${process.env.VUE_APP_HOPE_API_URL}login?token=${token}`, '_blank', 'noopener noreferrer');
    };

    return {
        error,
        tenantList,
        isTenantSystemFetchProgressing,
        isTokenFetchProgressing,
        initStatus,
        fetchList,
        getToken,
        simpleLogin,
        findAdminApiKey,
        findOperatorApiKey,
    };
};
