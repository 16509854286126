import { FetchExternalLoginTokenResponse, IAuthRepository } from '@/models/interface/Auth/IAuthRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class FetchExternalLoginTokenService {
    constructor(@inject('IAuthRepository') private readonly repository: IAuthRepository) {}

    async handle(systemId: number, tenantIdentifier: string, apiKey: string): Promise<FetchExternalLoginTokenResponse> {
        return this.repository.getExternalLoginToken(systemId, tenantIdentifier, apiKey);
    }
}
