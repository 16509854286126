









































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useCompanyMenu } from '@/composables/store/Management/useCompanyMenu';

export default defineComponent({
    setup() {
        const menuFlag = ref(false);
        const searchWord = ref('');

        onMounted(() => {
            initCompanyName();
            initSelectedCompany();
            fetchCompanyList();
        });

        const {
            companyMenuLabel,
            companyList,
            filterCompanyList,
            isDefaultLabel,
            isSelectedCompany,
            selectedCompany,
            initCompanyName,
            initSelectedCompany,
            fetchCompanyList,
        } = useCompanyMenu();

        const select = (): void => {
            menuFlag.value = !menuFlag.value;
        };

        watch(searchWord, (latest, old) => {
            if (latest === old) return;
            filterCompanyList(searchWord.value);
        });

        return {
            menuFlag,
            searchWord,
            companyMenuLabel,
            selectedCompany,
            isDefaultLabel,
            isSelectedCompany,
            companyList,
            select,
        };
    },
});
