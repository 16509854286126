import {
    ITenantMRepository,
    TenantFetchForSimpleLoginResponse,
} from '@/models/interface/Management/ITenantMRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class TenantFetchListForSimpleLoginService {
    constructor(@inject('ITenantMRepository') private readonly repository: ITenantMRepository) {}

    async handle(companyId: number): Promise<TenantFetchForSimpleLoginResponse> {
        return this.repository.getTenantIndexForSimpleLogin(companyId);
    }
}
