
























































































import { defineComponent, onMounted, watch } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import CompanyMenuComponent from '@/components/Management/molecules/CompanyMenu.vue';
import { useCompanyMenu } from '@/composables/store/Management/useCompanyMenu';
import { useSimpleLogin } from '@/composables/store/Management/useSimpleLogin';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        CompanyMenu: CompanyMenuComponent,
    },
    setup() {
        const { isDefaultSelectCompany, selectedCompany, setSearchCompanyName, fetchCompanyList } = useCompanyMenu();
        const {
            fetchList,
            simpleLogin,
            findAdminApiKey,
            findOperatorApiKey,
            tenantList,
            isTenantSystemFetchProgressing,
            isTokenFetchProgressing,
        } = useSimpleLogin();

        onMounted(() => {
            fetchCompanyList();
        });

        watch(selectedCompany, () => {
            if (isDefaultSelectCompany()) return;

            setSearchCompanyName(selectedCompany.value);
            fetchList(selectedCompany.value);
        });

        const loadingClass = (): string => (isTokenFetchProgressing.value ? 'deleting' : '');

        return {
            isDefaultSelectCompany,
            simpleLogin,
            loadingClass,
            findAdminApiKey,
            findOperatorApiKey,
            tenantList,
            isTenantSystemFetchProgressing,
            isTokenFetchProgressing,
        };
    },
});
